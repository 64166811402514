import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from '@mui/material'
import logo from '../assets/logo.svg'
import Appointment from '../assets/Appointments.svg'
import Directory from '../assets/directory and user mgt.svg'
import Configurable from '../assets/configurable intake form.svg'
import Documnet from '../assets/Document Management.svg'
import Messaging from '../assets/messaging.svg'
import Robust from '../assets/database 1.svg'

import Hero from '../assets/hero.png'
import Hippa from '../assets/hipaa.png'
import Hand from '../assets/hand.png'
import Crowd from '../assets/crowd.png'
import Care from '../assets/care.png'

import Case from '../assets/case.png'
import Case2 from '../assets/case2.png'
import Clients from '../assets/clients.png'
import Health from '../assets/health.png'

import Smile1 from '../assets/smile.png'
import Smile2 from '../assets/smile 2.png'

import map from '../assets/map2.png'

import { useState } from 'react'
import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
  Email,
  Fax,
  Phone,
} from '@mui/icons-material'
import { useNavigate } from 'react-router'

export default function Home() {
  const [type, setType] = useState<'case' | 'program' | 'client' | 'health'>(
    'case'
  )
  const navigate = useNavigate()
  function DCard({ img, text, description }: any) {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: { xs: 0, sm: 5 },
            py: { xs: 0, sm: 5 },
          }}
        >
          <Box sx={{ height: 140, display: 'flex', alignItems: 'flex-end' }}>
            <Box component='img' src={img} sx={{ width: 100, height: 120 }} />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography
              variant='h5'
              sx={{
                fontWeight: 'bolder',
                mx: 4,
                textAlign: 'center',
                my: 3,
                fontFamily: 'yorkten',
              }}
            >
              {text}
            </Typography>
            <Typography
              sx={{
                mx: 4,
                my: 2,
                // fontStyle: "italic",
                textAlign: 'center',
                // fontWeight: 600,
                fontSize: 17,
                fontFamily: 'araboto',
              }}
            >
              {description}
            </Typography>
          </Box>
        </Box>
      </Grid>
    )
  }

  function Item({
    type,
    data,
  }: {
    type: 'case' | 'program' | 'client' | 'health'
    data: string
  }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, my: 2 }}>
        <Box
          sx={{
            bgcolor:
              type === 'case'
                ? '#69AEFF'
                : type === 'program'
                ? '#20EFA4'
                : type === 'client'
                ? '#CAB914'
                : '#7B3BB0',
            borderTopLeftRadius: 7,
            borderBottomRightRadius: 7,
            width: 25,
            height: 10,
          }}
        ></Box>
        <Typography sx={{ fontWeight: 'bold', fontSize: 22 }}>
          {data}
        </Typography>
      </Box>
    )
  }
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: { xs: 2, sm: 10, md: 20 },
          px: { xs: 2, sm: 10, md: 15 },
          py: { xs: 2 },
        }}
      >
        <Box component='img' src={logo} sx={{ width: { xs: 50, sm: 80 } }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: { xs: 2, sm: 5, md: 10 },
            px: { xs: 2, sm: 5, md: 10 },
            // py: 7,
          }}
        >
          <a href='#main' style={{ textDecoration: 'none' }}>
            <Typography
              sx={{
                color: '#344068',
                fontWeight: 'bold',
                fontSize: { xs: 14, sm: 20 },
                fontFamily: 'araboto',
              }}
            >
              Features
            </Typography>
          </a>
          <a href='#usecases' style={{ textDecoration: 'none' }}>
            <Typography
              sx={{
                color: '#344068',
                fontWeight: 'bold',
                fontSize: { xs: 14, sm: 20 },
                fontFamily: 'araboto',
              }}
            >
              Use Cases
            </Typography>
          </a>
          <a href='#about' style={{ textDecoration: 'none' }}>
            <Typography
              sx={{
                color: '#344068',
                fontWeight: 'bold',
                fontSize: { xs: 14, sm: 20 },
                fontFamily: 'araboto',
              }}
            >
              About Us
            </Typography>
          </a>

          <a href='#contactus' style={{ textDecoration: 'none' }}>
            <Typography
              sx={{
                color: '#1B70DE',
                fontWeight: 'bold',
                fontSize: { xs: 15, sm: 20 },
                fontFamily: 'araboto',
              }}
            >
              CONTACT US
            </Typography>
          </a>
          {/* <a href='#about' style={{ textDecoration: 'none' }}>
        <Typography
          sx={{
            color: '#1B70DE',
            fontWeight: 'bold',
            fontSize: { xs: 15, sm: 18 },
          }}
        >
          LOGIN
        </Typography>
      </a> */}
        </Box>
      </Box>
      <Divider sx={{ color: '#DEEBF7' }} />
      <Box
        sx={{
          px: { xs: 2, sm: 10, md: 15 },
          height: { md: 'calc(100vh - 115px)' },
          my: { xs: 8, sm: 0 },
        }}
      >
        <Grid container spacing={5} sx={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={5.5}
            order={{ xs: 2, sm: 1 }}
            sx={{ height: '100%' }}
          >
            <Box
              sx={{
                height: '100%',
                display: { xs: 'block', sm: 'flex' },
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: { xs: 'center', sm: 'flex-start' },
                mt: { xs: 0, sm: -5 },
                px: { xs: 2, sm: 0 },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: 55, sm: 65, md: 70, xl: 90 },
                  color: '#0051B0',
                  lineHeight: 1,
                  fontFamily: 'araboto',
                }}
              >
                Enhancing Care Management
              </Typography>

              <Typography
                sx={{
                  // fontWeight: 'bold',
                  width: { xs: '90%', sm: '88%' },
                  my: 3,
                  lineHeight: 1,
                  fontSize: 21,
                  fontWeight: 'bold',
                  color: 'black',
                  // fontFamily: 'araboto',
                  fontFamily: 'yorkten',
                }}
              >
                REACH, CONNECT, AND SUPPORT YOUR CLIENTS SEAMLESSLY
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6.5}
            order={{ xs: 1, sm: 2 }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              component='img'
              src={Hero}
              sx={{ width: { xs: '100%', sm: '100%' } }}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ color: '#DEEBF7', mx: { xs: 5, sm: 10, md: 15 } }} />

      <Box sx={{ my: 10, mx: { xs: 2, sm: 10, md: 15 } }}>
        <Typography
          id='main'
          sx={{
            // fontSize: { xs: '30px', sm: '40px' },
            // fontStyle: 'normal',
            // fontWeight: 'bold',
            // background:
            //   'var(--newww, linear-gradient(90deg, #168BF8 0.21%, #02307A 99.79%))',
            // backgroundClip: 'text',
            // '-webkit-background-clip': 'text',
            // '-webkit-text-fill-color': 'transparent',
            // textAlign: 'center',
            // my: 5,
            fontSize: { xs: 20, sm: 30, md: 40, xl: 50 },
            color: '#0051B0',
            lineHeight: 1,
            fontFamily: 'araboto',
            textAlign: 'center',
            my: 5,
          }}
        >
          Enhance Platform Features
        </Typography>
        <Grid container spacing={3}>
          <DCard
            img={Messaging}
            text='Messaging'
            description='Secure and efficient communication tool that allows healthcare providers to exchange information quickly and effectively.'
          />
          <DCard
            img={Appointment}
            text='Appointments'
            description='Simplified scheduling system that enables easy booking, rescheduling, and tracking of patient appointments.'
          />
          <DCard
            img={Documnet}
            text='Document Management'
            description='Streamlined management of patient records, reports, and other essential documents in a secure and organized manner.'
          />
          <DCard
            img={Configurable}
            text='Configurable Intake Forms'
            description='Customizable forms that can be tailored to gather specific information from patients, enhancing the efficiency of data collection.'
          />
          <DCard
            img={Robust}
            text='Robust Reporting & Analytics'
            description='Powerful reporting tools that provide valuable insights into patient data, helping to improve healthcare delivery and decision-making.'
          />
          <DCard
            img={Directory}
            text='Directory & User Managemnt'
            description='Comprehensive directory and user management system that allows for efficient management of healthcare provider details and user roles.'
          />
        </Grid>
      </Box>

      <Box
        sx={{
          my: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
          bgcolor: '#F4F9FF',
          py: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box component='img' src={Hippa} />
          <Typography
            id='main'
            sx={{
              fontSize: '25px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontFamily: 'yorkten',
              background:
                'var(--newww, linear-gradient(90deg, #168BF8 0.21%, #02307A 99.79%))',
              backgroundClip: 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
              textAlign: 'center',
              my: 5,
            }}
          >
            HIPAA Secure
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box component='img' src={Hand} />
          <Typography
            id='main'
            sx={{
              fontSize: '25px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontFamily: 'yorkten',

              background:
                'var(--newww, linear-gradient(90deg, #168BF8 0.21%, #02307A 99.79%))',
              backgroundClip: 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
              textAlign: 'center',
              my: 5,
            }}
          >
            Tech-Driven
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box component='img' src={Crowd} />
          <Typography
            id='main'
            sx={{
              fontSize: '25px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontFamily: 'yorkten',

              background:
                'var(--newww, linear-gradient(90deg, #168BF8 0.21%, #02307A 99.79%))',
              backgroundClip: 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
              textAlign: 'center',
              my: 5,
            }}
          >
            Patient Centric
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box component='img' src={Care} />
          <Typography
            id='main'
            sx={{
              fontSize: '25px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontFamily: 'yorkten',

              background:
                'var(--newww, linear-gradient(90deg, #168BF8 0.21%, #02307A 99.79%))',
              backgroundClip: 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
              textAlign: 'center',
              my: 5,
            }}
          >
            Enhanced Care
          </Typography>
        </Box>
      </Box>

      <Box sx={{ my: 10 }}>
        <Typography
          id='usecases'
          sx={{
            fontSize: { xs: 20, sm: 30, md: 40, xl: 50 },
            color: '#0051B0',
            lineHeight: 1,
            fontFamily: 'araboto',
            textAlign: 'center',
            my: 5,
          }}
        >
          Enhanced Use Cases
        </Typography>

        <Box sx={{ pl: { xs: 0, md: 20 }, pr: { xs: 0, md: 10 } }}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row', sm: 'column' },
                  // alignItems: 'center',
                  justifyContent: { xs: 'space-evenly', sm: 'flex-end' },
                  gap: 3,
                  px: { xs: 1, sm: 0 },
                  my: { xs: 3, sm: 0 },
                }}
              >
                {type !== 'case' ? (
                  <IconButton sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <ArrowCircleLeftOutlined
                      sx={{
                        height: 50,
                        width: 50,
                        color:
                          type === 'program'
                            ? '#1866D2'
                            : type === 'client'
                            ? '#20EFA4'
                            : '#CAB914',
                      }}
                      onClick={() => {
                        if (type === 'program') {
                          setType('case')
                        } else if (type === 'client') {
                          setType('program')
                        } else {
                          setType('client')
                        }
                      }}
                    />
                  </IconButton>
                ) : (
                  <Box sx={{ width: 80 }}></Box>
                )}
                <Box
                  sx={{
                    bgcolor: type === 'case' ? '#F4F9FF' : 'white',
                    borderLeft: {
                      xs: 'none',
                      sm: type === 'case' ? '4px solid #1866D2' : 'none',
                    },
                    borderBottom: {
                      xs: type === 'case' ? '4px solid #1866D2' : 'none',
                      sm: 'none',
                    },
                    py: 3,
                    width: 340,
                    display: {
                      xs: type === 'case' ? 'flex' : 'none',
                      sm: 'flex',
                    },
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onMouseEnter={() => setType('case')}
                >
                  <Box sx={{ width: '80%' }}>
                    <Typography
                      sx={{
                        color: type === 'case' ? '#1C71DF' : 'black',
                        fontWeight: 'bold',
                        fontSize: 22,
                        fontFamily: 'yorkten',
                        textAlign: { xs: 'center', sm: 'left' },
                      }}
                    >
                      Case Managers
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    bgcolor: type === 'program' ? '#F4FFFA' : 'white',
                    borderLeft: {
                      xs: 'none',
                      sm: type === 'program' ? '4px solid #20EFA4' : 'none',
                    },
                    borderBottom: {
                      xs: type === 'program' ? '4px solid #20EFA4' : 'none',
                      sm: 'none',
                    },
                    py: 3,
                    width: 340,
                    display: {
                      xs: type === 'program' ? 'flex' : 'none',
                      sm: 'flex',
                    },
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onMouseEnter={() => setType('program')}
                >
                  <Box sx={{ width: '80%' }}>
                    <Typography
                      sx={{
                        color: type === 'program' ? '#20EFA4' : 'black',
                        fontWeight: 'bold',
                        fontSize: 22,
                        fontFamily: 'yorkten',
                        textAlign: { xs: 'center', sm: 'left' },
                      }}
                    >
                      Program Managers
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    bgcolor: type === 'client' ? '#FFFEF4' : 'white',
                    borderLeft: {
                      xs: 'none',
                      sm: type === 'client' ? '4px solid #CAB914' : 'none',
                    },
                    borderBottom: {
                      xs: type === 'client' ? '4px solid #CAB914' : 'none',
                      sm: 'none',
                    },
                    py: 3,
                    width: 340,
                    display: {
                      xs: type === 'client' ? 'flex' : 'none',
                      sm: 'flex',
                    },
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onMouseEnter={() => setType('client')}
                >
                  <Box sx={{ width: '80%' }}>
                    <Typography
                      sx={{
                        color: type === 'client' ? '#CAB914' : 'black',
                        fontWeight: 'bold',
                        fontSize: 22,
                        fontFamily: 'yorkten',
                        textAlign: { xs: 'center', sm: 'left' },
                      }}
                    >
                      Clients
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    bgcolor: type === 'health' ? '#FAF4FF' : 'white',
                    borderLeft: {
                      xs: 'none',
                      sm: type === 'health' ? '4px solid #7B3BB0' : 'none',
                    },
                    borderBottom: {
                      xs: type === 'health' ? '4px solid #7B3BB0' : 'none',
                      sm: 'none',
                    },
                    py: 3,
                    width: 340,
                    display: {
                      xs: type === 'health' ? 'flex' : 'none',
                      sm: 'flex',
                    },
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onMouseEnter={() => setType('health')}
                >
                  <Box sx={{ width: '80%' }}>
                    <Typography
                      sx={{
                        color: type === 'health' ? '#7B3BB0' : 'black',
                        fontWeight: 'bold',
                        fontSize: 22,
                        fontFamily: 'yorkten',
                        textAlign: { xs: 'center', sm: 'left' },
                      }}
                    >
                      Health Care
                    </Typography>
                  </Box>
                </Box>
                {type !== 'health' ? (
                  <IconButton sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <ArrowCircleRightOutlined
                      sx={{
                        height: 50,
                        width: 50,
                        color:
                          type === 'case'
                            ? '#20EFA4'
                            : type === 'program'
                            ? '#CAB914'
                            : '#7B3BB0',
                      }}
                      onClick={() => {
                        if (type === 'case') {
                          setType('program')
                        } else if (type === 'program') {
                          setType('client')
                        } else {
                          setType('health')
                        }
                      }}
                    />
                  </IconButton>
                ) : (
                  <Box sx={{ width: 80 }}></Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Box
                sx={{
                  // width: '75%',
                  bgcolor:
                    type === 'case'
                      ? '#F4F9FF'
                      : type === 'program'
                      ? '#F4FFFA'
                      : type === 'client'
                      ? '#FFFEF4'
                      : '#FAF4FF',
                  display: { xs: 'block', sm: 'flex' },
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  py: 5,
                  px: { xs: 2, sm: 5 },
                  borderRadius: 7,
                }}
              >
                {type === 'case' ? (
                  <Box>
                    <Item
                      type='case'
                      data='Following up with clients and writing case notes with ease'
                    />
                    <Item
                      type='case'
                      data='Communicate with clients on sms, email & other omni-channels'
                    />
                    <Item
                      type='case'
                      data='Set appointments with clients and health care providers'
                    />
                    <Box
                      component='img'
                      src={Case2}
                      sx={{ width: { xs: '100%' } }}
                    />
                  </Box>
                ) : type === 'program' ? (
                  <Box>
                    <Item
                      type='program'
                      data='Generate Custom Report & Program Overviews'
                    />
                    <Item
                      type='program'
                      data='Easily pull Clients Information, Demographics & Outcomes'
                    />
                    <Item
                      type='program'
                      data='Find Program bright-spots and bottlenecks effortlesly'
                    />
                    <Box
                      component='img'
                      src={Case}
                      sx={{ width: { xs: '100%' } }}
                    />
                  </Box>
                ) : type === 'client' ? (
                  <Box>
                    <Item
                      type='client'
                      data='Communicate with case managers and clients seamlessly'
                    />
                    <Item
                      type='client'
                      data='Request appointments and track their outcome'
                    />
                    <Item
                      type='client'
                      data='Create and manage client goals and tasks'
                    />
                    <Item type='client' data='Enhanced Document Storage' />
                    <Box
                      component='img'
                      src={Clients}
                      sx={{ width: { xs: '100%' } }}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Item
                      type='health'
                      data='Use this tool to store and update documents'
                    />
                    <Item
                      type='health'
                      data='Can manage their client information'
                    />
                    <Item
                      type='health'
                      data='Easily and Securely manage system users'
                    />
                    <Item
                      type='health'
                      data='Access and configure intake and program forms'
                    />
                    <Box
                      component='img'
                      src={Health}
                      sx={{ width: { xs: '100%' } }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider sx={{ my: { xs: 5, sm: 15 }, mx: { xs: 5, sm: 15 } }} />
      <Box
        sx={{
          bgcolor: '#20EFA4',
          height: 300,
          position: 'relative',
          mt: 40,
          mb: 30,
          display: { xs: 'none', sm: 'flex' },
        }}
        id='about'
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Box
            sx={{
              bgcolor: '#F4F9FF',
              height: 650,
              width: 700,
              borderTopLeftRadius: 400,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: '30px', sm: '50px' },
                  fontStyle: 'normal',
                  // fontWeight: 900,
                  // background:
                  //   "var(--newww, linear-gradient(90deg, #63B4FF 0.21%, #002977 99.79%))",
                  // backgroundClip: "text",
                  // "-webkit-background-clip": "text",
                  // "-webkit-text-fill-color": "transparent",
                  my: 2,
                  fontFamily: 'araboto',
                  color: '#0051B0',
                }}
              >
                Who We Are
              </Typography>
              <Typography
                sx={{ width: 350, fontSize: 16, fontFamily: 'araboto' }}
              >
                Enhance is a team of dedicated healthcare and technology
                professionals committed to revolutionizing the way healthcare
                providers manage patient information. Our diverse team brings
                together expertise in healthcare, data security, and user
                experience to create an EHR solution that is secure, efficient,
                and easy to use.
              </Typography>
            </Box>
          </Box>
          <Box component='img' src={Smile1} sx={{ height: 650 }} />
        </Box>
      </Box>

      <Box
        sx={{
          bgcolor: '#82C3F5',
          height: 300,
          position: 'relative',
          mt: 60,
          mb: 40,
          display: { xs: 'none', sm: 'flex' },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Box component='img' src={Smile2} sx={{ height: 650 }} />
          <Box
            sx={{
              bgcolor: '#F4F9FF',
              height: 650,
              width: 700,
              borderTopRightRadius: 400,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ px: 5 }}>
              <Typography
                sx={{
                  fontSize: { xs: '30px', sm: '50px' },
                  fontStyle: 'normal',
                  color: '#0051B0',
                  my: 2,
                  fontFamily: 'araboto',
                }}
              >
                Our Mission
              </Typography>
              <Typography sx={{ width: 350, fontFamily: 'araboto' }}>
                Our mission is to enhance healthcare delivery through efficient
                and secure EHR solutions. We believe that by streamlining the
                management of patient records, we can give healthcare providers
                more time to focus on what truly matters - patient care.
              </Typography>
            </Box>
            <Box sx={{ px: 5 }}>
              <Typography
                sx={{
                  fontSize: { xs: '30px', sm: '50px' },
                  fontStyle: 'normal',
                  // fontWeight: 900,
                  color: '#0051B0',

                  // background:
                  //   'var(--newww, linear-gradient(90deg, #63B4FF 0.21%, #002977 99.79%))',
                  // backgroundClip: 'text',
                  // '-webkit-background-clip': 'text',
                  // '-webkit-text-fill-color': 'transparent',
                  my: 2,
                  fontFamily: 'araboto',
                }}
              >
                Our Vission
              </Typography>
              <Typography sx={{ width: 350, fontFamily: 'araboto' }}>
                Our vision is to be the leading provider of EHR solutions,
                transforming how healthcare providers manage patient
                information. We aim to set new standards in the industry,
                driving innovation and excellence in patient data management.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          my: 10,
          display: { xs: 'block', sm: 'none' },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            id='about'
          >
            <Box sx={{ px: 7, bgcolor: '#F4F9FF', py: 4 }}>
              <Typography
                sx={{
                  fontSize: { xs: '30px', sm: '50px' },
                  fontStyle: 'normal',
                  // background:
                  //   "var(--newww, linear-gradient(90deg, #63B4FF 0.21%, #002977 99.79%))",
                  // backgroundClip: "text",
                  // "-webkit-background-clip": "text",
                  // "-webkit-text-fill-color": "transparent",
                  my: 2,
                  color: '#0051B0',
                  fontFamily: 'araboto',
                }}
              >
                Who We Are
              </Typography>
              fontFamily: 'araboto',
              <Typography
                sx={{
                  fontFamily: 'araboto',
                }}
              >
                Enhance is a team of dedicated healthcare and technology
                professionals committed to revolutionizing the way healthcare
                providers manage patient information. Our diverse team brings
                together expertise in healthcare, data security, and user
                experience to create an EHR solution that is secure, efficient,
                and easy to use.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component='img'
              src={Smile1}
              sx={{
                width: { xs: '100%' },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          my: 10,
          display: { xs: 'block', sm: 'none' },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
            }}
          >
            <Box sx={{ px: 5, bgcolor: '#F4F9FF', pt: 3 }}>
              <Typography
                sx={{
                  fontSize: { xs: '30px', sm: '60px' },

                  fontStyle: 'normal',
                  color: '#0051B0',
                  fontFamily: 'araboto',

                  my: 2,
                }}
              >
                Our Mission
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'araboto',
                }}
              >
                Our mission is to enhance healthcare delivery through efficient
                and secure EHR solutions. We believe that by streamlining the
                management of patient records, we can give healthcare providers
                more time to focus on what truly matters - patient care.
              </Typography>
            </Box>
            <Box sx={{ px: 5, bgcolor: '#F4F9FF', pb: 3 }}>
              <Typography
                sx={{
                  fontSize: { xs: '30px', sm: '60px' },
                  fontStyle: 'normal',
                  my: 2,
                  color: '#0051B0',
                  fontFamily: 'araboto',
                }}
              >
                Our Vission
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'araboto',
                }}
              >
                Our vision is to be the leading provider of EHR solutions,
                transforming how healthcare providers manage patient
                information. We aim to set new standards in the industry,
                driving innovation and excellence in patient data management.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component='img'
              src={Smile2}
              sx={{
                width: { xs: '100%' },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: { xs: 10, sm: 20 }, px: { xs: 0, md: 14 } }}>
        <Grid container spacing={5} sx={{ height: 750 }}>
          <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
              id='contactus'
            >
              <Box sx={{ width: { xs: '90%', sm: 550 } }}>
                <Typography
                  sx={{
                    fontSize: { xs: '30px', sm: '50px' },
                    fontStyle: 'normal',
                    color: '#0051B0',
                    my: 2,
                    fontFamily: 'araboto',
                  }}
                >
                  Contact us
                </Typography>
                <Box
                  sx={{
                    width: { xs: '100%', sm: 550 },
                  }}
                >
                  <TextField
                    placeholder='Name'
                    fullWidth
                    sx={{ my: 2 }}
                    InputProps={{
                      sx: { borderRadius: 2.5 },
                    }}
                  />
                  <TextField
                    placeholder='Email'
                    fullWidth
                    sx={{ my: 2 }}
                    InputProps={{
                      sx: { borderRadius: 2.5 },
                    }}
                  />
                  <TextField
                    placeholder='Phone Number'
                    fullWidth
                    sx={{ my: 2 }}
                    InputProps={{
                      sx: { borderRadius: 2.5 },
                    }}
                  />
                  <TextField
                    placeholder='Reason'
                    fullWidth
                    sx={{ mt: 2, mb: 4 }}
                    InputProps={{
                      sx: { borderRadius: 2.5 },
                    }}
                  />
                  <Button
                    variant='contained'
                    fullWidth
                    sx={{ py: 2, borderRadius: 2.5 }}
                  >
                    Send
                  </Button>
                  {/* <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    my: 4,
                  }}
                >
                  <Phone />
                  <Box>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>
                      PHONE
                    </Typography>
                    <Typography
                      sx={{ color: 'primary.main', fontSize: 12 }}
                    >
                      +123456789
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    my: 4,
                  }}
                >
                  <Fax />
                  <Box>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>
                      FAX
                    </Typography>
                    <Typography
                      sx={{ color: 'primary.main', fontSize: 12 }}
                    >
                      +123456789
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    my: 4,
                  }}
                >
                  <Email />
                  <Box>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>
                      EMAIL
                    </Typography>
                    <Typography
                      sx={{ color: 'primary.main', fontSize: 12 }}
                    >
                      info@info.com
                    </Typography>
                  </Box>
                </Box>
              </Box> */}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} order={{ xs: 1, sm: 2 }}>
            <Box
              sx={{
                alignItems: 'center',
                justifyContent: { xs: 'center', md: 'flex-end' },
                height: '100%',
                display: { xs: 'none', sm: 'flex' },
                pr: { md: 5, xl: 10 },
              }}
            >
              <Box
                sx={{
                  bgcolor: '#82C3F5',
                  width: 320,
                  height: '100%',
                  borderTopRightRadius: 30,
                  borderBottomRightRadius: 30,
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    height: 600,
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    boxShadow: '10px 10px 10px 10px rgba(0,0,0,0.07)',
                  }}
                  component='img'
                  src={map}
                ></Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'block', sm: 'none' },
                height: 400,
                width: '100%',
              }}
              component='img'
              src={map}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ mx: { xs: 3, md: 14 } }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 5,
          py: 5,
          px: { xs: 3, md: 14 },
        }}
      >
        <Button onClick={() => navigate('/terms')} sx={{ color: 'black' }}>
          Terms
        </Button>
        <Button onClick={() => navigate('/privacy')} sx={{ color: 'black' }}>
          Privacy Policy
        </Button>
      </Box>
    </Box>
  )
}
