import { ChevronLeft } from '@mui/icons-material'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router'

export default function Privacy() {
  function DataCard({ title, detail }: { title: string; detail: string }) {
    return (
      <Box sx={{ my: 1 }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: 20,
            fontFamily: 'sans-serif',
            px: { xs: 3, sm: 10, md: 30 },
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            px: { xs: 3, sm: 10, md: 30 },
            py: 3,
            fontFamily: 'revert-layer',
          }}
        >
          {detail}
        </Typography>
      </Box>
    )
  }
  const navigate = useNavigate()
  return (
    <Box>
      <Box
        sx={{
          pt: 5,
          px: 5,
          pb: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <IconButton onClick={() => navigate('/')}>
          <ChevronLeft sx={{ width: 30, height: 30 }} />
        </IconButton>
        <Typography
          sx={{
            fontWeight: 'normal',
            color: '#0051B0',
            fontFamily: 'sans-serif',
            fontSize: 20,
          }}
        >
          Privacy Policy
        </Typography>
      </Box>
      <Divider sx={{ mt: 1, mb: 5, mx: 5 }} />
      <DataCard
        title='ENHANCE COMMITMENT TO PRIVACY'
        detail='Enhance strives to be the technology partner of choice for physicians
          and healthcare professionals, offering solutions to streamline their
          practices. We understand the highly confidential nature of the
          information you entrust to us, including personal details, patient
          data, and insurance-related transactions. This privacy policy outlines
          our unwavering commitment to safeguarding the privacy and security of
          patients, physicians, and all users of our platform.'
      />
      <DataCard
        title='Security Measures'
        detail='Whenever you submit or update information on Enhance, we prioritize security and privacy by implementing robust measures. This includes utilizing Secure Socket Layer (SSL) encryption technology. Additionally, for enhanced protection, the information you provide is stored on a separate server. Its important to note, however, that we cannot guarantee absolute protection against information loss, misuse, or unauthorized access by third parties.'
      />
      <Box sx={{ my: 1 }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: 20,
            fontFamily: 'sans-serif',
            px: { xs: 3, sm: 10, md: 30 },
          }}
        >
          Information Sharing Practices
        </Typography>
        <Typography
          sx={{
            px: { xs: 3, sm: 10, md: 30 },
            py: 3,
            fontFamily: 'revert-layer',
          }}
        >
          Except as outlined below, Enhance will not disclose any personally
          identifiable information (Physician Information or Practice
          Information) to unaffiliated companies. Any Physician or Practice
          Information shared with entities affiliated with Enhance will be
          handled in accordance with this privacy policy, unless you are
          otherwise notified. We may consider disclosing Physician or Practice
          Information in the following limited circumstances:
          <br />
          <Box sx={{ p: 2 }}>
            <li>
              Compliance with Legal Requirements: When compelled by valid legal
              requirements, such as a law, regulation, search warrant, subpoena,
              or court order.
            </li>
            <li>
              Special Circumstances: In exceptional cases, such as a physical
              threat to you or others.
            </li>
            <li>
              Third-Party Vendors: We may share Physician or Practice
              Information with vendors and suppliers who require it to provide
              us with products and services related to the optimal operation and
              maintenance of our platform ("Vendors"). While we strive to ensure
              these Vendors do not further use or disclose your information, we
              cannot guarantee their absolute compliance.
            </li>
          </Box>
        </Typography>
      </Box>
      <DataCard
        title='Aggregate Information'
        detail='Enhance may provide non-identifiable, aggregated data to third parties. This information cannot be used to identify or contact you directly. For instance, we might inform third parties about the number of users on our platform and their general activity patterns. We might also share with a pharmaceutical company (advertiser or not) that "30% of our users reside east of the Mississippi" or that "25% of our users have explored alternative medicine." Depending on the circumstances, we may or may not charge for this type of anonymized data.'
      />
      <Box sx={{ my: 1 }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: 20,
            fontFamily: 'sans-serif',
            px: { xs: 3, sm: 10, md: 30 },
          }}
        >
          Your Responsibilities
        </Typography>
        <Typography
          sx={{
            px: { xs: 3, sm: 10, md: 30 },
            py: 3,
            fontFamily: 'revert-layer',
          }}
        >
          You are responsible for taking all necessary precautions to ensure no
          unauthorized person gains access to your Enhance password or account.
          This includes:
          <br />
          <Box sx={{ p: 2 }}>
            <li>
              Controlling the dissemination and use of activation codes and
              passwords.
            </li>
            <li>
              Authorizing, monitoring, and controlling access and use of your
              Enhance account and password.
            </li>
            <li>
              Promptly notifying Enhance of any need to deactivate a password.
            </li>
          </Box>
          By using Enhance, you grant us and all entities involved in operating
          our platform the right to receive, transmit, monitor, retrieve, store,
          and utilize your Physician or Practice Information, including
          potentially privileged and confidential details under applicable state
          and federal laws, solely in connection with the operation of Enhance.
          We cannot and do not assume any responsibility or liability for
          information you submit to Enhance, or for the use or misuse of
          information transmitted or received via our platform by you or third
          parties
        </Typography>
      </Box>
      <DataCard
        title='Security Measures'
        detail='Enhance prioritizes the utmost security of your Physician and Practice Information. We have implemented a two-tiered system that combines leading technical safeguards and a strict code of conduct for authorized employees who can access customer information.'
      />
      <DataCard
        title='Technical Safeguards'
        detail='We utilize SSL to safeguard the integrity and privacy of the Physician and Practice Information you provide to us online. As an additional security measure, your information is stored on a separate server with encrypted passwords.'
      />
      <DataCard
        title='Employee Code of Conduct'
        detail='Only authorized Enhance employees are permitted to access your Physician and Practice Information. All our employees must adhere to our Privacy Policy, and any violations are subject to disciplinary action, up to and including termination.'
      />
      <DataCard
        title='Links to Third-Party Sites'
        detail='This privacy statement applies solely to the Enhance website. Our website may contain links to other websites. Please be aware that Enhance is not responsible for the privacy practices of such external sites. We encourage you to review the privacy statements of every website that collects personal or practice information from you.'
      />
      <DataCard
        title='Updates to this Privacy Policy'
        detail='Enhance reserves the right to modify this Privacy Policy at any time by posting revisions on our website. Your continued use of the platform constitutes your acceptance of the provisions of this Privacy Policy, and continued use after such changes are posted signifies your acceptance of each revised Privacy Policy. If you disagree with the terms of this Privacy Policy or any revised version, please discontinue using the platform immediately.'
      />
      <Box sx={{ my: 3 }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: 20,
            fontFamily: 'sans-serif',
            px: { xs: 3, sm: 10, md: 30 },
          }}
        >
          Privacy Policy for SMS and 10DLC
        </Typography>
      </Box>
      <DataCard
        title='1. Data Collection'
        detail='We collect your phone number and any related information necessary to provide the messaging service, including:'
      />
      <Box sx={{ px: { xs: 3, sm: 10, md: 33 } }}>
        <li>Phone numbers</li>
        <li>Message contents (if required for service)</li>
        <li>Timestamp of sent and received messages</li>
      </Box>
      <Box sx={{ py: 2, px: { xs: 3, sm: 10, md: 30 } }}>
        This data is processed and stored in accordance with our general Privacy
        Policy and used only for the purpose of providing messaging services.
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: 20,
            fontFamily: 'sans-serif',
            px: { xs: 3, sm: 10, md: 30 },
          }}
        >
          2. Data Usage
        </Typography>
      </Box>
      <Box sx={{ px: { xs: 3, sm: 10, md: 33 }, py: 2 }}>
        <li>
          {' '}
          We use the information collected for operational purposes, including
          managing message delivery, troubleshooting, and improving service
          quality.
        </li>
        <li>
          Your information may also be used to comply with legal obligations or
          carrier requirements, including 10DLC registration with U.S. mobile
          carriers.
        </li>
      </Box>
      <DataCard
        title='3. Data Sharing'
        detail='We will not share your information with third parties except:'
      />{' '}
      <Box sx={{ px: { xs: 3, sm: 10, md: 33 }, mb: 2 }}>
        <li>
          To comply with legal obligations, such as subpoenas or government
          requests.
        </li>
        <li>
          With trusted service providers who perform messaging services on our
          behalf, under strict confidentiality agreements.
        </li>
        <li>
          As part of a merger, acquisition, or any form of corporate change.
        </li>
      </Box>
      <DataCard
        title='4. Data Security'
        detail='We implement appropriate technical and organizational measures to protect your personal information, including encryption and secure storage. However, we cannot guarantee the absolute security of data.'
      />{' '}
      <DataCard
        title='5. Opt-out and Data Deletion'
        detail='You may opt out of messaging services at any time by texting “STOP”. Upon opt-out, we will cease messaging and, upon request, delete your personal data from our messaging system, subject to our retention policies.'
      />{' '}
      <DataCard
        title='6. Your Rights'
        detail='You have the right to access, modify, or delete your personal data, as well as to request limitations on how we use your data. Please contact us at (contact info) for such requests.'
      />
      <DataCard
        title='7. Changes to This Policy'
        detail='We may update this Privacy Policy from time to time to reflect changes in legal or regulatory obligations or in our services. All changes will be posted on this page, and continued use of our services implies acceptance of these changes. '
      />
    </Box>
  )
}
