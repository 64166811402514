import { ChevronLeft } from '@mui/icons-material'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router'

export default function Terms() {
  function DataCard({ title, detail }: { title: string; detail: string }) {
    return (
      <Box sx={{ my: 1 }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: 17,
            px: { xs: 3, sm: 10, md: 30 },
            fontFamily: 'sans-serif',
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            px: { xs: 3, sm: 10, md: 30 },
            py: 2,
            fontFamily: 'revert-layer',
          }}
        >
          {detail}
        </Typography>
      </Box>
    )
  }
  const navigate = useNavigate()
  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          pt: 5,
          px: 5,
          pb: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <IconButton onClick={() => navigate('/')}>
          <ChevronLeft sx={{ width: 30, height: 30 }} />
        </IconButton>
        <Typography
          sx={{
            fontWeight: 'normal',
            color: '#0051B0',
            fontFamily: 'serif',
            fontSize: 20,
          }}
        >
          Terms
        </Typography>
      </Box>
      <Divider sx={{ mt: 1, mb: 5, mx: 5 }} />
      <Box sx={{ my: 3 }}>
        <Typography
          sx={{
            px: { xs: 3, sm: 10, md: 30 },
            py: 2,
            fontFamily: 'revert-layer',
          }}
        >
          This Agreement ("Agreement") is made and entered by and between
          Enhance, a Los Angeles, California limited liability company
          ("Enhance"), and you and your medical practice, business or similar
          organization (collectively, the "Practice"). BY CLICKING "I AGREE" OR
          BY OTHERWISE SIGNING-UP FOR AN ENHANCE ACCOUNT, OR BY ACCESSING OR
          USING THE SERVICES (AS DEFINED BELOW), THE PRACTICE IS ENTERING INTO
          THIS ENHANCE SUBSCRIPTION AGREEMENT and agrees to be bound by its
          terms and conditions.
        </Typography>
      </Box>
      <Box sx={{ my: 3 }}>
        <Typography
          sx={{
            px: { xs: 3, sm: 10, md: 30 },
            py: 2,
            fontFamily: 'revert-layer',
          }}
        >
          PLEASE READ THIS AGREEMENT CAREFULLY, AND DO NOT SIGN-UP FOR AN
          ACCOUNT OR USE THE SERVICES IF THE PRACTICE IS UNWILLING OR UNABLE TO
          BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. Enhance and
          the Practice are collectively referred to as the "Parties".
        </Typography>
      </Box>
      <DataCard
        title='RECITALS'
        detail='WHEREAS, the Practice is a medical practice licensed to practice medicine; WHEREAS, Enhance has developed and implemented a web-based software application for practice management and electronic health records, which software application is being licensed by Enhance pursuant to and in accordance with the terms and conditions of this Agreement as a service to the Practice (the "Services"); and WHEREAS, the Practice desires to license use of the Services from Enhance, and Enhance desires to license use of the Services to the Practice, pursuant to the terms and conditions of this Agreement (the "Subscription Terms and Conditions").
NOW, THEREFORE, for good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the Parties, intending to be legally bound, hereby agree as follows:'
      />{' '}
      <DataCard
        title='SUBSCRIPTION TERMS'
        detail='If the Practice has purchased a license to or has otherwise subscribed to the Services as indicated in Enhances applicable records or if the Practice otherwise continues to access or use the Services after the expiration of any applicable trial period, the Practice hereby agrees to the following terms and conditions (which terms and conditions when effective supersede and replace any prior agreements):'
      />{' '}
      <DataCard
        title='1. LICENSE AND USE'
        detail='License Grant. Enhance hereby grants the Practice, upon the terms and conditions contained herein, a limited, non-transferable, non-sublicensable, non-exclusive license for all licensed professionals associated with the Practice, including, without limitation, all physicians, nurse practitioners, physician assistants and therapists (collectively, "Licensed Users") to use the Services, during the Term (as defined in Section 10 below) in accordance with Enhances then current documentation and only: (i) in the ordinary course of the Practices normal and ordinary internal business, and (ii) in accordance with applicable federal, state, and local laws, rules, and regulations ("Intended Purpose"). The Practice covenants and agrees that the Services shall not be used by any person or entity other than the Licensed Users for the Intended Purpose and shall prevent any subcontractor or agent of the Practice or any other third party from using the Services. The Practice acknowledges that the Services will encompass information about items, devices, products and/or services not owned by Enhance, but instead provided by a third party or third parties, inclusive of, but not limited to, third-party vendors and manufacturers ("Third Party Services").'
      />{' '}
      <DataCard
        title='RECITALS'
        detail='WHEREAS, the Practice is a medical practice licensed to practice medicine; WHEREAS, Enhance has developed and implemented a web-based software application for practice management and electronic health records, which software application is being licensed by Enhance pursuant to and in accordance with the terms and conditions of this Agreement as a service to the Practice (the "Services"); and WHEREAS, the Practice desires to license use of the Services from Enhance, and Enhance desires to license use of the Services to the Practice, pursuant to the terms and conditions of this Agreement (the "Subscription Terms and Conditions").
NOW, THEREFORE, for good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, the Parties, intending to be legally bound, hereby agree as follows:'
      />
      <Typography
        sx={{
          px: { xs: 3, sm: 10, md: 30 },
          py: 2,
          fontFamily: 'revert-layer',
        }}
      >
        License Restrictions. In furtherance of Section 1(a) hereof, the
        Practice shall not, and shall not permit any third party (including, but
        not limited to, affiliates, employees or agents of any third party) to:
        (i) duplicate, modify, decompile or reverse engineer any of the Services
        for any reason; or (ii) resell the Services to any unrelated third
        party. This is a license, not a sale. The Practice shall not, and shall
        not allow anyone to: (1) circumvent any security feature of the
        Services; (2) use the Services to provide services (including
        processing, commercial timesharing, rental, sharing, application service
        provider, "service office," or "service bureau" arrangements) to any
        third party; (3) permit any third party to view, use, access, or copy
        the Services; or (4) disclose, publish, broadcast, sell, or otherwise
        redistribute the Services. The Practice shall maintain the
        confidentiality and security of any password, account, and other
        identification information provided with respect to the Services and not
        to provide it to any other person or entity (including, but not limited
        to affiliates, employees or agents of any third party). The Practice
        shall be responsible for all use of, and activities that occur under,
        its password(s), account, and other identification information and for
        any actions that take place through its access to the Services (whether
        conducted by the Practice or another). The Practice agrees to use the
        Services only for the Intended Purpose.
      </Typography>
      <Typography
        sx={{
          px: { xs: 3, sm: 10, md: 30 },
          py: 2,
          fontFamily: 'revert-layer',
        }}
      >
        Modification Enhance reserves the right to modify the Services and their
        respective features and functionality at any time, without notice or
        warning and without liability. Enhance shall have the right to add,
        drop, modify, or replace, all or any part of the Third Party Services at
        any time.
      </Typography>
      <DataCard
        title='2. FEES AND PAYMENT'
        detail='Subscription Charges The Practice agrees to pay Enhance the subscription fees set forth in the then-current Enhance order form or other written agreement executed by the Parties (the "Order Form"). Unless otherwise specified in the Order Form, subscription fees are due and payable in advance on the first day of each Subscription Term (defined below). Enhance reserves the right to change its fees at any time, upon thirty (30) days prior written notice to the Practice.'
      />{' '}
      <Typography
        sx={{
          px: { xs: 3, sm: 10, md: 30 },
          py: 2,
          fontFamily: 'revert-layer',
        }}
      >
        Payment Method The Practice agrees to provide Enhance with a valid
        payment method, such as a credit card or bank account information, for
        automatic billing. The Practice is responsible for any applicable taxes,
        fees, or surcharges associated with its use of the Services. Enhance may
        suspend or terminate the Practice's access to the Services if any
        payment is past due.
      </Typography>
      <DataCard
        title='3. TERM AND TERMINATION'
        detail='Term The term of this Agreement shall commence on the Effective Date (as defined in the first paragraph) and shall continue for the initial term specified in the Order Form (the "Initial Term"). The Agreement shall automatically renew for successive renewal terms of the same duration as the Initial Term (each, a "Renewal Term"), unless either Party provides written notice to the other Party at least thirty (30) days prior to the expiration of the then-current term (the "Subscription Term").'
      />
      <Typography
        sx={{
          px: { xs: 3, sm: 10, md: 30 },
          py: 2,
          fontFamily: 'revert-layer',
        }}
      >
        TERMINATION
      </Typography>
      <Box sx={{ py: 2, px: { xs: 3, sm: 10, md: 30 } }}>
        <li>
          By Enhance: Enhance may terminate this Agreement for cause upon
          written notice to the Practice if the Practice breaches any material
          provision of this Agreement and fails to cure such breach within
          thirty (30) days' written notice from Enhance. Enhance may also
          terminate this Agreement immediately upon written notice to the
          Practice if the Practice becomes insolvent, bankrupt, or files a
          petition for bankruptcy or other proceeding for the relief of debtors.
        </li>
        <li>
          By Practice: The Practice may terminate this Agreement upon written
          notice to Enhance.
        </li>
      </Box>
      <DataCard
        title='4. DATA OWNERSHIP'
        detail='Practice Data The Practice owns all data input by the Practice or its Licensed Users into the Services (the "Practice Data"). Enhance shall not use or disclose the Practice Data for any purpose other than providing the Services to the Practice. Enhance shall implement and maintain commercially reasonable technical and organizational measures to protect the Practice Data from unauthorized access, disclosure, alteration, or destruction.'
      />
      <Typography
        sx={{
          px: { xs: 3, sm: 10, md: 30 },
          py: 2,
          fontFamily: 'revert-layer',
        }}
      >
        Enhance Data Enhance owns all data relating to the Services and the
        operation of the Services (the "Enhance Data"). The Practice
        acknowledges that the Enhance Data may include aggregate data about the
        use of the Services by all Practices. Enhance may use and disclose the
        Enhance Data for any purpose, provided that Enhance does not disclose
        any information that can be reasonably used to identify the Practice or
        any Licensed User.
      </Typography>
      <DataCard
        title='5. CONFIDENTIALITY'
        detail='Each Party agrees to hold in confidence all Confidential Information of the other Party. "Confidential Information" means any information disclosed by a Party (the "Disclosing Party") to the other Party (the "Receiving Party") that is designated as confidential or that, by its nature, ought to be treated as confidential. The Receiving Party shall not use or disclose any Confidential Information of the Disclosing Party for any purpose other than as necessary to perform its obligations under this Agreement.'
      />{' '}
      <DataCard
        title='6. DISCLAIMER OF WARRANTIES'
        detail='THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" AND ENHANCE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. ENHANCE DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.'
      />{' '}
      <DataCard
        title='7. LIMITATION OF LIABILITY'
        detail='IN NO EVENT SHALL ENHANCE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE SERVICES, EVEN IF ENHANCE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ENHANCES TOTAL LIABILITY ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE SERVICES SHALL NOT EXCEED THE FEES PAID BY THE PRACTICE TO ENHANCE HEREUNDER DURING THE THREE (3) MONTHS PRECEDING THE DATE THE CLAIM AROSE.'
      />{' '}
      <DataCard
        title='8. INDEMNIFICATION'
        detail='The Practice agrees to indemnify, defend, and hold harmless Enhance from and against any and all claims, losses, damages, expenses, and liabilities (including attorneys fees) arising out of or relating to the Practices use of the Services, or any breach of this Agreement by the Practice.'
      />{' '}
      <DataCard
        title='9. FORCE MAJEURE'
        detail='Neither Party shall be liable for any delay or failure to perform its obligations under this Agreement due to causes beyond such Partys reasonable control, including, without limitation, acts of God, acts of war or terrorism, strikes or labor disputes, fires, floods, earthquakes, or governmental regulations.'
      />{' '}
      <DataCard
        title='10. ENTIRE AGREEMENT'
        detail='This Agreement constitutes the entire agreement between the Parties with respect to the subject matter hereof and supersedes all prior or contemporaneous communications, representations, or agreements, whether oral or written.'
      />{' '}
      <DataCard
        title='11. AMENDMENT'
        detail='This Agreement may be amended only by a written instrument executed by both Parties.'
      />{' '}
      <DataCard
        title='12. GOVERNING LAW'
        detail='This Agreement shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of laws principles.'
      />{' '}
      <DataCard
        title='13. WAIVER'
        detail='No waiver by either Party of any breach or default hereunder shall be deemed to be a waiver of any subsequent breach or default.'
      />{' '}
      <DataCard
        title='14. SEVERABILITY'
        detail='If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall remain in full force and effect.'
      />{' '}
      <DataCard
        title='15. NOTICES'
        detail='All notices and other communications hereunder shall be in writing and shall be deemed to have been duly given when delivered personally, sent by certified or registered mail, postage prepaid, return receipt requested, or sent by overnight courier, addressed as follows:'
      />
      <Typography
        sx={{
          px: { xs: 3, sm: 10, md: 30 },
          py: 2,
          fontFamily: 'revert-layer',
        }}
      >
        If to Enhance:
      </Typography>
      <Typography
        sx={{
          px: { xs: 3, sm: 10, md: 30 },
          py: 2,
          fontFamily: 'revert-layer',
        }}
      >
        Enhance LLC
      </Typography>
      <Typography
        sx={{
          px: { xs: 3, sm: 10, md: 30 },
          py: 2,
          fontFamily: 'revert-layer',
        }}
      >
        If to the Practice:
      </Typography>
      <Typography
        sx={{
          px: { xs: 3, sm: 10, md: 30 },
          py: 2,
          fontFamily: 'revert-layer',
        }}
      >
        [Name of Practice] [Address of Practice]
      </Typography>
      <Typography
        sx={{
          px: { xs: 3, sm: 10, md: 30 },
          py: 2,
          fontFamily: 'revert-layer',
        }}
      >
        or to such other address as either Party may have hereafter specified in
        writing to the other Party.
      </Typography>
      <DataCard
        title='16. ASSIGNMENT'
        detail='This Agreement may not be assigned by the Practice without the prior written consent of Enhance.'
      />
      <DataCard
        title='17. HEADINGS'
        detail='The headings used in this Agreement are for convenience only and shall not be considered in construing this Agreement.'
      />{' '}
      <DataCard
        title='18. COUNTERPARTS'
        detail='This Agreement may be executed in counterparts, each of which shall be deemed an original, but all of which together shall constitute one and the same instrument.'
      />
      <Typography
        sx={{
          px: { xs: 3, sm: 10, md: 30 },
          fontFamily: 'revert-layer',
        }}
      >
        IN WITNESS WHEREOF, the Parties have executed this Agreement as of the
        date first written above.
      </Typography>
      <Typography
        sx={{
          px: { xs: 3, sm: 10, md: 30 },
          fontFamily: 'revert-layer',
          fontWeight: 'bold',
          my: 4,
        }}
      >
        10DLC Terms of Service
      </Typography>
      <DataCard
        title='Introduction'
        detail='These terms apply to the use of SMS, MMS, and any other messaging services provided through the platform (EHR Platform name) using a 10DLC (10-Digit Long Code) number. By accessing or using our messaging services, you agree to be bound by these terms and conditions, our general Terms of Service, and Privacy Policy.'
      />
      <DataCard
        title='1. Messaging Consent'
        detail='By using our services, you agree to receive transactional and/or promotional text messages, as applicable. Consent to receive messages is required for specific communications (such as appointment reminders) and can be withdrawn at any time by following the opt-out process detailed below.'
      />
      <Box sx={{ my: 1 }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: 17,
            px: { xs: 3, sm: 10, md: 30 },
            fontFamily: 'sans-serif',
          }}
        >
          2. User Responsibilities
        </Typography>
      </Box>
      <Box sx={{ py: 2, px: { xs: 3, sm: 10, md: 30 } }}>
        <li>
          Users must comply with all applicable laws and carrier regulations
          when using SMS and other messaging services through our platform.
        </li>
        <li>
          You agree not to use messaging services for any illegal purposes,
          including but not limited to fraud, harassment, or unsolicited
          promotional content (spam).
        </li>
        <li>
          You will not use the platform to send sensitive personal information
          such as financial data or health-related data unless expressly
          authorized and protected under relevant laws.
        </li>
      </Box>
      <Box sx={{ my: 1 }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: 17,
            px: { xs: 3, sm: 10, md: 30 },
            fontFamily: 'sans-serif',
          }}
        >
          3. Opt-in & Opt-out Procedures
        </Typography>
      </Box>
      <Box sx={{ py: 2, px: { xs: 3, sm: 10, md: 30 } }}>
        <li>
          Users must explicitly opt in to receive messages. By entering your
          phone number, you acknowledge and agree to receive text messages.
        </li>
        <li>
          To opt out, text “STOP” to the provided number. Upon opting out, you
          will no longer receive any messages. For help or information, text
          “HELP” or contact support at (insert contact info).
        </li>
      </Box>
      <DataCard
        title='4. Data Rates'
        detail='Message and data rates may apply based on your carriers policy. You are responsible for any fees associated with text messaging, including those set by your mobile provider.'
      />
      <DataCard
        title='5. Message Frequency'
        detail='The number of messages you receive will vary based on your engagement with our services and specific services requested. You will not receive more than (X) messages per month unless explicitly agreed upon.'
      />
      <DataCard
        title='6. Prohibited Content'
        detail='You agree not to send any content that:'
      />
      <Box sx={{ py: 2, px: { xs: 3, sm: 10, md: 30 } }}>
        <li>
          Is unlawful, obscene, threatening, defamatory, or otherwise
          objectionable.
        </li>
        <li>
          Violates any applicable local, national, or international laws or
          regulations.
        </li>
        <li>Contains malware, viruses, or other harmful software.</li>
      </Box>
      <DataCard
        title='7. Service Availability'
        detail='Messaging services may be delayed or unavailable based on your mobile carrier, network conditions, or external technical issues. We do not guarantee message delivery.'
      />
    </Box>
  )
}
